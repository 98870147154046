import React from 'react'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import NextLink from 'next/link'
import { PlainLayout, useTranslation } from '@plandi/common'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import Image from 'next/image'
import { QueryClient } from 'react-query'
import { newLandingApi, TRANSLATE } from '../features/new-landing/common'
import { dehydrate } from 'react-query/hydration'

export const useNotFoundStyles = makeStyles(() =>
  createStyles({
    root: {
      margin: 'auto',
      paddingTop: 88,
      paddingBottom: 88,
      maxWidth: 485,
      minHeight: '100vh',
      textAlign: 'center'
    },

    content: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      marginTop: 173
    },

    logo: {
      display: 'inline-block'
    },

    title: {
      marginBottom: 16
    },

    subTitle: {
      marginBottom: 68
    },

    link: {
      textDecoration: 'none'
    }
  })
)

const NotFound: React.FC = () => {
  const classes = useNotFoundStyles()
  const { data: translate } = useTranslation()
  return (
    <PlainLayout>
      <div className={classes.root}>
        <Image
          width="136"
          alt=""
          height="40"
          className={classes.logo}
          src="/assets/logo.svg"
        />
        <div className={classes.content}>
          <Typography variant="h1" color="secondary">
            404
          </Typography>
          <Typography className={classes.subTitle} variant="h3">
            {translate?.['page-not-found']}
          </Typography>
          <NextLink href="/">
            <Button
              component="a"
              variant="contained"
              color="secondary"
              size="large"
              className={classes.link}
            >
              {translate?.['go-to-main']}
            </Button>
          </NextLink>
        </div>
      </div>
    </PlainLayout>
  )
}

export default NotFound

export const getStaticProps = async (ctx: { locale: string }) => {
  const queryClient = new QueryClient()
  await queryClient.prefetchQuery(TRANSLATE, () =>
    newLandingApi.getTranslate(ctx.locale)
  )
  return {
    props: {
      dehydratedState: dehydrate(queryClient)
    }
  }
}
